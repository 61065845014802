<template>
	<div>
		<b-row>
			<b-col lg="6" md="6" sm="6" class="heading-title">
				<h4> TDS List</h4>
				<b-breadcrumb class="p-0 m-0" id="breadcrumb-heading">
					<b-breadcrumb-item to="/dashboard" custom> Home </b-breadcrumb-item>
					<b-breadcrumb-item @click="goBack()" > Taxation </b-breadcrumb-item>
					<b-breadcrumb-item active>TDS List</b-breadcrumb-item>
				</b-breadcrumb>
			</b-col>
			<b-col lg="6" md="6" sm="6" class="btns-header"> 
				
			</b-col>
		</b-row>
	<br>
    <div class="card mt-4">
					
					<div class="card-header">
						</div>
					<div class="card-body">
						
    <b-table
				:busy="load"
				:fields="fields"
				:items="bookings"
				responsive
				hover
				bordered
				show-empty
				empty-text="No Data Available"
				class="text-center"
				> 
					<template  #cell(amount)="data">
						<span v-html="currency_check(data.item.amount)"></span>
					</template>
                    <template  #cell(gst)="data">
						<span v-html="currency_check(data.item.gst)"></span>
					</template>
                    <template  #cell(tds)="data">
						<span v-html="currency_check(data.item.tds)"></span>
					</template>
					<template  #cell(booking_id)="data">
					<!-- <router-link :to="'/finance/tax/view_tds_list/' + data.item.booking_id">
                <i class="fas fa-eye fa-lg text-dark"></i>
              </router-link> -->
			{{data.item.booking_id}}
			<i class="fas fa-eye fa-lg text-dark" ></i>
					</template>
					
				
				</b-table>
				
     </div>
     </div>
     </div>
     
</template>

<script>
	import axios from "axios";
	export default {
		name: "mybooking",
		
		data: () => ({
			month_id:'',
            fields: [
			//  'index',
			{
				key: "Date",
				label: "Date",
				sortable: true,
				thClass: 'text-left',
				tdClass: 'text-left',
			},
			{
				key: "vendor_name",
				label: "Invoice Number",
				sortable: true,
				thClass: 'text-left',
				tdClass: 'text-left',
			},
			
		
			/*  {
				key: "billing_phone",
				label: "Billing Phone",
				},
				{
				key: "billing_email",
				label: "Billing Email",
				sortable: true,
			},*/
			{
				key: "amount",
				label: "Full amount",
				sortable: true,
				thClass: 'text-left',
				tdClass: 'text-right',
			},
			{
				key: "gst",
				label: "GST Amount",
				sortable: true,
                tdClass: 'text-right',
				thClass: 'text-left',
			},
			{
				key: "tds",
				label: "TDS Deducted",
				sortable: true,
				tdClass: 'text-right',
				thClass: 'text-left',
			}, 
			/* 
			{
				key: "gst_amount",
				label: "VB Amount",
				sortable: true,
                tdClass: 'text-right',
			}, */
           
			
		
			],
			bookings: [],
			options: [10, 20, 30, 50],
			
			pageSize: 10,
			show: false,
			load: true,
			filter: "",
			search: "",
			CurrentPage: 1,
			sortBy: "created_at",
			
			page: 1,
			count: 0,
			totalData: "",
			totalRows: "",
			
			PerPage: "50",
			venue_names: [],
			start_date: "",
			end_date: "",
			booked_on: "",
			venue_name_value: "",
			curncy: "",
			month_id2:''
		}),
		methods: {
            key_search() {
				const value = "1";
				this.handlePageChange(value);
			},
			handlePageChange(value) {
				this.page = value;
				this.load = true;
				this.bookingListing();
			},
			applyFilter() {
				const value = "1";
				this.handlePageChange(value);
			},
			getRequestParams(page, pageSize, sortBy = "created_at") {
				let params = {};
				if (page) {
					params["page"] = page - 1;
				}
				if (pageSize) {
					params["size"] = pageSize;
				}
				params["sortBy"] = sortBy;
				return params;
			},
			loadInvoice()
            {
                axios.post("/admin/admin_month_tds_details",{
						month:this.month_id,
						month2:this.month_id2,
					})
					.then((resp) => {
						this.show = false;
						this.load = false;
for (var i = 0; i < resp.data.bookings.length; i++) {
                               // tax_amount = resp.data.bookings[i].total_booking_price*18/100;
								this.bookings.push({
									Date: this.date_format(resp.data.bookings[i].inv_from),
									vendor_name: resp.data.bookings[i].invoice_id,
									amount: this.formatPrice(Math.round(resp.data.bookings[i].p_vb_amount)),
									gst: this.formatPrice(Math.round(resp.data.bookings[i].gst)),
									tds: this.formatPrice(Math.round(resp.data.bookings[i].tds)),
									
								});
							}


                        if (resp.data.status_code == 200) {
					//	this.bookings = [];
						if (resp.data.status) {
							this.count = this.totalData = resp.data.total_records;
							this.totalRows = resp.data.bookings.length;
                            
                        //  var tds_amount;
						/* deduct_tds_from_vb
: 
17402
invoice_amt
: 
443916
invoice_tax_total
: 
79904.88
month
: 
"2023-12-01"
payable_amounts
: 
20598
tds_amount
: 
355
vb_amount
: 
17757
vb_tax_amt
: 
3196 */

                            /* for (var i = 0; i < resp.data.bookings.length; i++) {
                                vb_amount = Math.round(resp.data.bookings[i].total_invoice*4/100);
                              var  gst_amount = Math.round(vb_amount*18/100);
                              var total = parseInt(vb_amount)+parseInt(gst_amount);
								this.bookings.push({
									Date: this.date_format(resp.data.bookings[i].from_date),
									invoice_number: '',
									auto_increment: resp.data.bookings[i].auto_increment,
									select_month: resp.data.bookings[i].select_month,
									booking_auto_id: resp.data.bookings[i].booking_auto_id,
							
									total_booking_price:
									this.formatPrice(vb_amount),
									
									gst_amount:
									this.formatPrice(gst_amount),	
                                    total:
									this.formatPrice(total),
									//booked_on: resp.data.bookings[i].booked_on,
                                   
									
								});
							} */
							//this.show = false;
						//	this.load = false;
							} else {
							//this.count = this.totalData = resp.data.total_records;
							//this.totalRows = resp.data.bookings.length;
							
							//this.show = false;
							//this.load = false;
						}
					}
                })
            },
            currency_check(amt) {
				
				return '<i class="mdi mdi-currency-inr"></i>'+amt;
				//return '<i class="mdi '+this.curncy+'"></i>'+amt;
			},
			getProfile() {
				axios.get("/admin/get_profile").then((resp) => {
					if (resp.data.status_code == 200) {
						this.curncy= resp.data.users.currencys;
					}
				});
			},
            formatPrice(value) {
				var num = value ? value : 0;
			return num.toLocaleString('en-IN')
			},
			date_format:function(date)
		{
			var d1 = new Date(date);
			var ms = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
		//	return d1.getDate() + '' + this.getDateOrdinal(d1) +' ' + ms[d1.getMonth()] + ' ' + d1.getFullYear();
			return ms[d1.getMonth()] + ' ' + d1.getFullYear();
		},
		getDateOrdinal(date) {
				let day = date.getDate();
				if (day > 3 && day < 21) return 'th';
				switch (day % 10) {
					case 1:  return "st";
					case 2:  return "nd";
					case 3:  return "rd";
					default: return "th";
				}
			},
			goBack()
			{
				this.$router.go(-1)
			},
			lastroutename() {
  //let returnval = '';

  //const routerstack = this.$router.history.stack;
  const idx = this.$router.history.index;

  /* if (idx > 0) {
    returnval = routerstack[idx - 1].name;
  } */

  return idx;
}
		},
		mounted() {
			document.documentElement.scrollTop = 0;
            this.month_id = this.$route.params.id;
            this.month_id2 = this.$route.params.id2;
            this.loadInvoice();
            this.getProfile();
		},
	};
</script>

<style>
	
</style>